var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "occupy",
          expression: "widget.compType === 'occupy'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "宽度(%)" } },
          [
            _c("el-input-number", {
              attrs: { min: 0, max: 100 },
              model: {
                value: _vm.widget.properties.width,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "width", $$v)
                },
                expression: "widget.properties.width",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "高度(px)" } },
          [
            _c("el-input-number", {
              staticClass: "input",
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.height,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "height", $$v)
                },
                expression: "widget.properties.height",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "上传地址" } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: { clearable: "" },
              model: {
                value: _vm.widget.properties.action,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "action", $$v)
                },
                expression: "widget.properties.action",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_Jump_mode") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                clearable: "",
                placeholder: _vm.$t("lang_please_enter"),
              },
              model: {
                value: _vm.widget.properties.linkUrl,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "linkUrl", $$v)
                },
                expression: "widget.properties.linkUrl",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_Jump_mode") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.target,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "target", $$v)
                  },
                  expression: "widget.properties.target",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "新开窗口", value: "_blank" },
                }),
                _c("el-option", {
                  attrs: { label: "替换当前窗口", value: "_self" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "显示标签" } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _vm.widget.properties.showLabel
          ? [
              _c(
                "el-form-item",
                { attrs: { label: "标签宽度" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.widget.properties.labelWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.widget.properties, "labelWidth", $$v)
                      },
                      expression: "widget.properties.labelWidth",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签高度" } },
                [
                  _c("el-input-number", {
                    staticClass: "input",
                    attrs: { min: 0 },
                    model: {
                      value: _vm.widget.properties.minHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.widget.properties, "minHeight", $$v)
                      },
                      expression: "widget.properties.minHeight",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签位置" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.widget.properties.labelPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                        },
                        expression: "widget.properties.labelPosition",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("lang_align_left"),
                          value: "left",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("lang_center"),
                          value: "center",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("lang_align_right"),
                          value: "right",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }